import { on as onEvent } from "common/utils/ui/event";
import { authAccountGA } from "components/jiffy/deliver-to/deliver-to-ga-events";
import initDeliverToSite from "components/jiffy/deliver-to/deliver-to-site";
import initDeliverToApplication from "components/jiffy/deliver-to/deliver-to-application";
import initDeliverToTooltips from "components/jiffy/deliver-to/deliver-to-tooltips";

// Styles
import "components/jiffy/deliver-to/deliver-to-toggle-mobile/deliver-to-toggle-mobile.scss";
import "components/jiffy/deliver-to/deliver-to-toggle-desktop/deliver-to-toggle-desktop.scss";
import "components/jiffy/deliver-to/deliver-to-form/deliver-to-form.scss";
import "components/jiffy/deliver-to/deliver-to-guest/deliver-to-guest.scss";
import "components/jiffy/deliver-to/deliver-to-modal/deliver-to-modal.scss";
import "components/jiffy/deliver-to/deliver-to-user/deliver-to-user.scss";

const initSignInEvents = () => {
  onEvent("click", ".js-deliver-to-auth-guest", authAccountGA, true);
};
document.addEventListener("DOMContentLoaded", () => {
  initDeliverToTooltips();
  initSignInEvents();

  const jiffyDeliverTo = window.MOBILE_APP ? initDeliverToApplication() : initDeliverToSite();
  window.JiffyDeliverTo = {
    updateUserAddressInHeader: addressID => {
      // Update Toggle
      jiffyDeliverTo.userForm.onSelectAddressCallback(selectedAddress => {
        jiffyDeliverTo.deliverToToggle.fillAddress(selectedAddress);
      });
      // Clear form with correct address
      jiffyDeliverTo.userForm.clearForm(addressID);
      // Call callback for filling address
      jiffyDeliverTo.userForm.selectAddress();
    },
    updateZipCodeInHeader: ({ zipcode = "", city = "" }) => {
      // Update Toggle
      jiffyDeliverTo.deliverToToggle.fillAddress({
        address: `${city.toLowerCase()} ${zipcode}`,
        zipcode
      });
      // Update Form
      jiffyDeliverTo.zipCodeForm.fillZipCodeField(zipcode);
    },
    // force toggle reload if it loaded after page load
    reloadToggle: () => {
      jiffyDeliverTo.initDeliverToToggle();
      jiffyDeliverTo.initEvents();
    }
  };
});
