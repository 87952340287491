import { api } from "jiffy-api";
import DeliverToModalClass from "./deliver-to-modal-class";

export default class DeliverToModalSiteClass extends DeliverToModalClass {
  constructor(props) {
    super(props);

    this.autoHideModalTimeout = 5000;
  }

  async fetchModalBody() {
    const deliverToModalResponse = await api.product.fetchDeliverToModal();
    this.$modal.innerHTML = deliverToModalResponse.modal;
    super.fetchDomElements();
  }

  reloadPageWithModal() {
    setTimeout(() => {
      window.location.reload();
    }, this.autoHideModalTimeout);

    $(this.$modal).on("hide.bs.modal", () => {
      window.location.reload();
    });

    return this;
  }
}
