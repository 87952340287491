import { on as onEvent } from "common/utils/ui/event";
import { readCookie } from "common/utils/cookie";
import { isFunction } from "common/utils/helpers/is";

export default class DeliverToToggleClass {
  constructor(
    toggleSelector,
    options = {
      chosenPdpAddressSelector: ""
    }
  ) {
    this.$toggle = document.querySelector(toggleSelector);
    this.$chosenPdpAddresses = document.querySelectorAll(options.chosenPdpAddressSelector);
    this.options = options;
    this.toggleCallback = null;

    onEvent("click", toggleSelector, this.onToggle.bind(this), true);
  }

  getToggleDataset() {
    return this.$toggle.dataset;
  }

  onToggleCallback(callback) {
    this.toggleCallback = callback;

    return this;
  }

  onToggle() {
    const { mixpanelContext = "header" } = this.getToggleDataset();
    isFunction(this.toggleCallback) && this.toggleCallback(mixpanelContext);

    return this;
  }

  fillPDPAddress(address = "", username = "") {
    if (!this.$chosenPdpAddresses.length) return this;

    this.$chosenPdpAddresses.forEach($pdpAddress => {
      if (address) {
        // eslint-disable-next-line no-param-reassign
        $pdpAddress.innerHTML = `
          Deliver to <span>${username ? `${username} - ` : ""}${address}</span>
        `;
      } else {
        // eslint-disable-next-line no-param-reassign
        $pdpAddress.innerHTML = "Select Your Address";
      }
    });

    return this;
  }

  // eslint-disable-next-line class-methods-use-this
  getAddressFromCookies() {
    const address = readCookie("address");
    const deliverToName = readCookie("deliver_to_name");
    const zipcode = readCookie("zipcode");

    return {
      address: address ? decodeURIComponent(address).replace(/\+/g, " ") : null,
      username: deliverToName ? decodeURIComponent(deliverToName) : null,
      zipcode: zipcode ? decodeURIComponent(zipcode) : null
    };
  }
}
