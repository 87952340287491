export const {
  api,
  routes,
  get,
  post,
  put,
  patch,
  destroy,
  resolveOptions,
  request,
  toQueryString,
  resolvePathArgs,
  makePath,
  makeFullPath
} = window.JiffyApi;
