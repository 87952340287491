import DeliverToToggleClass from "./deliver-to-toggle-class";

export default class DeliverToToggleMobileClass extends DeliverToToggleClass {
  constructor(
    selector,
    options = {
      chosenAddressSelector: ""
    }
  ) {
    super(selector, options);

    this.$chosenAddress = document.querySelector(options.chosenAddressSelector);

    this.fillAddress(super.getAddressFromCookies());
  }

  fillAddress({ address = "", username = "" }) {
    if (this.$chosenAddress === null) return this;

    if (address) {
      this.$chosenAddress.innerHTML = `
      Deliver to <span>${username ? `${username} - ` : ""}${address}</span>
    `;
    } else {
      this.$chosenAddress.innerHTML = "Select a location to get earliest delivery date";
    }

    super.fillPDPAddress(address, username);

    return this;
  }
}
