import { track } from "jiffy-analytics";
import { isFunction } from "common/utils/helpers/is";
import { isTouchDevice } from "common/utils/browser";
import { closePopupGA, openPopupGA } from "../deliver-to-ga-events";

export default class DeliverToModalClass {
  constructor(
    props = {
      modalSelector: "",
      modalInputSelector: "",
      addressSelector: ""
    }
  ) {
    this.trackAttributeContext = "header";
    this.props = props;
    this.closeModalCallback = null;
    this.openModalCallback = null;
    this.deliveryAddressShow = null;

    this.fetchDomElements();
    this.initModalEvents();
  }

  initModalEvents() {
    $(this.$modal)
      .on("shown.bs.modal", this.onShownModal.bind(this))
      .on("hidden.bs.modal", this.onHiddenModal.bind(this));
  }

  fetchDomElements() {
    this.$modal = document.querySelector(this.props.modalSelector);
    this.$modalInput = document.querySelector(this.props.modalInputSelector);

    return this;
  }

  onShownModal() {
    this.deliveryAddressShow = this.getDeliveryAddress();
    this.updateAccessibility();
    this.trackVisibilityEvent("zipcode_modal_show");

    openPopupGA(this.getVisitorType());
    isFunction(this.openModalCallback) && this.openModalCallback();

    return this;
  }

  onHiddenModal() {
    if (this.deliveryAddressShow === this.getDeliveryAddress()) {
      this.trackVisibilityEvent("zipcode_modal_hide");
      closePopupGA(this.getVisitorType());
    }
    isFunction(this.closeModalCallback) && this.closeModalCallback();

    return this;
  }

  onCloseModalCallback(callback) {
    this.closeModalCallback = callback;

    return this;
  }

  onOpenModalCallback(callback) {
    this.openModalCallback = callback;

    return this;
  }

  getModalDataset() {
    return this.$modal.dataset || {};
  }

  getVisitorType() {
    return this.getModalDataset().visitorType;
  }

  openModal() {
    $(this.$modal).modal("show");

    return this;
  }

  getTrackAttributeContext() {
    return this.trackAttributeContext;
  }

  updateAccessibility() {
    !isTouchDevice() && this.$modalInput?.focus();

    return this;
  }

  updateTrackAttributeContext(context) {
    this.trackAttributeContext = context;

    return this;
  }

  trackVisibilityEvent(action) {
    const $zipcode = this.$modalInput?.value;

    track("header_zipcode", {
      action,
      context: this.trackAttributeContext,
      zipcode: $zipcode
    });

    return this;
  }

  getDeliveryAddress() {
    return document.querySelector(this.props.addressSelector)?.innerText;
  }
}
