import { api } from "jiffy-api";
import { isFunction } from "common/utils/helpers/is";
import { on as onEvent } from "common/utils/ui/event";
import { manageAccountGA, submitSuccessGA } from "../deliver-to-ga-events";

export default class DeliverToUserFormClass {
  constructor(
    props = {
      formSelector: "",
      inputsSelector: "",
      addressesLinkSelector: ""
    }
  ) {
    this.props = props;
    this.orderNumber = null;
    this.selectAddressCallback = null;

    onEvent("change", this.props.inputsSelector, this.selectAddress.bind(this), true);
    onEvent("click", this.props.addressesLinkSelector, manageAccountGA, true);

    this.fetchDomElements();
  }

  fetchDomElements() {
    this.$form = document.querySelector(this.props.formSelector);
    this.$inputs = document.querySelectorAll(this.props.inputsSelector);

    return this;
  }

  // eslint-disable-next-line class-methods-use-this
  getOrderNumber() {
    return document.querySelector(".js-order-number-container")?.dataset?.orderNumber;
  }

  onSelectAddressCallback(callback) {
    this.selectAddressCallback = callback;

    return this;
  }

  selectAddress() {
    const formData = new FormData(this.$form);
    const addressID = formData.get("address_id");
    const address = formData.get(`address_${addressID}`);
    const username = formData.get(`username_${addressID}`);
    const splitAddress = address.split(" ");
    const zipcode = splitAddress.pop();
    const city = splitAddress.join(" ");

    submitSuccessGA("address");

    if (isFunction(this.selectAddressCallback)) {
      this.selectAddressCallback({
        zipcode,
        address: address.toLowerCase(),
        city,
        username,
        addressID
      });
    }

    return this;
  }

  async setAddress(addressID) {
    await api.account.addresses.setOne(
      {
        order_number: this.getOrderNumber()
      },
      {
        ship_address_id: addressID
      }
    );
  }

  async unsetAddress() {
    await api.account.addresses.removeOne({
      order_number: this.getOrderNumber()
    });
  }

  clearForm(addrID = null) {
    this.$inputs.forEach($input => {
      if (parseInt($input.value, 10) === parseInt(addrID, 10)) {
        $input.setAttribute("checked", "checked");
      } else {
        $input.removeAttribute("checked");
      }
    });
    this.$form?.reset();

    return this;
  }
}
