import { track } from "jiffy-analytics";
import DeliverToZipCodeFormClass from "../classes/deliver-to-zip-code-form-class";
import "./deliver-to-tooltip-mobile.scss";

const $getTooltip = () => document.getElementById("js-deliver-to-tooltip-mobile");
const $getMobileButton = () => document.getElementById("js-deliver-to-toggle-mobile");
const $getMessageWrapper = () =>
  document.getElementById("js-deliver-to-tooltip-mobile-message-wrapper");
const $getErrorWrapper = () =>
  document.getElementById("js-deliver-to-tooltip-mobile-error-wrapper");

const toggleError = showError => {
  $getMessageWrapper()?.classList.toggle("hidden", showError);
  $getErrorWrapper()?.classList.toggle("hidden", !showError);
};

const showTooltip = () => {
  $getTooltip().classList.remove("hidden");
  $getMobileButton()?.classList.add("hidden");
};

const initDeliverToTooltip = () => {
  const $tooltip = $getTooltip();
  if (!$tooltip) return;

  const mobileForm = new DeliverToZipCodeFormClass({
    formSelector: "#js-deliver-to-tooltip-mobile-form",
    inputSelector: "#js-deliver-to-tooltip-mobile-input",
    loadingSelector: ".js-deliver-to-tooltip-mobile-loading",
    submitSelector: "#js-deliver-to-tooltip-mobile-submit",
    errorSelector: "#js-deliver-to-tooltip-mobile-form-error"
  });

  mobileForm.onSubmitCallback(({ zipcode }) => {
    toggleError(false);
    mobileForm.setLoading(true);
    mobileForm.preventLoading();
    track("header_zipcode", {
      action: "zipcode_mobile_tooltip_apply",
      context: "header",
      zipcode
    });
    window.location.reload();
  });

  mobileForm.onErrorCallback(() => {
    toggleError(true);
  });

  showTooltip();
};

export default initDeliverToTooltip;
