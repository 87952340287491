import { sendDataLayer, sendDataLayerOnLinkClickEvent } from "jiffy-analytics";

/**
 * @param visitorType[string] - guest | user
 */

export const openPopupGA = visitorType => {
  sendDataLayer({
    genericEvent: true,
    eventCategory: "Location Selector",
    event: "open: popup",
    eventLabel: visitorType
  });
};

export const closePopupGA = visitorType => {
  sendDataLayer({
    genericEvent: true,
    eventCategory: "Location Selector",
    event: "close: with no actions",
    eventLabel: visitorType
  });
};

/**
 * @param addressType[string] - address | zipcode
 */

export const submitSuccessGA = addressType => {
  sendDataLayer({
    genericEvent: true,
    eventCategory: "Location Selector",
    event: "submit: success",
    eventLabel: addressType
  });
};

export const submitErrorGA = addressType => {
  sendDataLayer({
    genericEvent: true,
    eventCategory: "Location Selector",
    event: "submit: error",
    eventLabel: addressType
  });
};

export const authAccountGA = (event, target) => {
  sendDataLayerOnLinkClickEvent(
    event,
    {
      genericEvent: true,
      eventCategory: "Location Selector",
      event: "click: button: sign in"
    },
    target
  );
};

export const manageAccountGA = (event, target) => {
  sendDataLayerOnLinkClickEvent(
    event,
    {
      genericEvent: true,
      eventCategory: "Location Selector",
      event: "click: button: manage addresses"
    },
    target
  );
};

export default {};
