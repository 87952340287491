const MODE = {
  BLANKS: "blanks",
  MADE: "made"
};

class PageMode {
  // eslint-disable-next-line class-methods-use-this
  get mode() {
    return window.jiffy?.madeStore?.getters?.["app/mode"];
  }

  get isMadeMode() {
    return this.mode === MODE.MADE;
  }

  get isBlanksMode() {
    return this.mode === MODE.BLANKS;
  }
}

const pageMode = new PageMode();

export default pageMode;
