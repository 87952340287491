import { api } from "jiffy-api";
import { track } from "jiffy-analytics";
import { isFunction } from "common/utils/helpers/is";
import { on as onEvent } from "common/utils/ui/event";
import { submitErrorGA, submitSuccessGA } from "../deliver-to-ga-events";

export default class DeliverToZipCodeFormClass {
  constructor(
    props = {
      formSelector: "",
      inputSelector: "",
      loadingSelector: "",
      submitSelector: "",
      errorSelector: ""
    }
  ) {
    this.props = props;
    this.trackAttributeContext = "header";
    this.submitCallback = null;
    this.errorCallback = null;
    this.isPreventLoading = false;

    onEvent("submit", props.formSelector, this.submitForm.bind(this), true);
    this.fetchDomElements();
  }

  fetchDomElements() {
    this.$form = document.querySelector(this.props.formSelector);
    this.$input = document.querySelector(this.props.inputSelector);
    this.$loading = document.querySelector(this.props.loadingSelector);
    this.$submit = document.querySelector(this.props.submitSelector);
    this.$error = document.querySelector(this.props.errorSelector);

    return this;
  }

  onSubmitCallback(callback) {
    this.submitCallback = callback;

    return this;
  }

  onErrorCallback(callback) {
    this.errorCallback = callback;

    return this;
  }

  clearErrors() {
    this.$error.innerText = "";

    return this;
  }

  clearForm() {
    this.clearErrors();
    this.$form?.reset();

    return this;
  }

  preventLoading() {
    this.isPreventLoading = true;

    return this;
  }

  setLoading(isLoading) {
    if (this.isPreventLoading) return null;

    if (isLoading) {
      this.$input.setAttribute("disabled", "disabled");
      this.$submit?.setAttribute("disabled", "disabled");
      this.$loading.classList.remove("hidden");
    } else {
      this.$input.removeAttribute("disabled");
      this.$submit?.removeAttribute("disabled");
      this.$loading.classList.add("hidden");
    }

    return this;
  }

  catchError(message = "Something went wrong. Please try again later.") {
    this.$error.innerText = message;
    submitErrorGA("zipcode");
    isFunction(this.errorCallback) && this.errorCallback(message);

    return this;
  }

  async submitForm(e) {
    e.preventDefault();
    this.setLoading(true);
    this.clearErrors();

    try {
      const data = await api.jiffyshirts.locations.create({
        zipcode: this.$input.value
      });
      this.parseZipcodeResponse(data);
    } catch (error) {
      this.catchError(error.message);
    } finally {
      this.setLoading(false);
    }
  }

  parseZipcodeResponse = ({ error, message, session = {} }) => {
    if (!error && session.zipcode && session.city) {
      submitSuccessGA("zipcode");
      this.trackZipcodeEvent(session.zipcode);
      isFunction(this.submitCallback) &&
        this.submitCallback({
          address: `${session.city.toLowerCase()} ${session.zipcode}`,
          zipcode: session.zipcode,
          city: session.city
        });
    } else if (error) {
      this.catchError(message);
    } else {
      this.catchError("Please enter a valid zipcode.");
    }

    return this;
  };

  updateTrackAttributeContext(context) {
    this.trackAttributeContext = context;

    return this;
  }

  trackZipcodeEvent(zipcode) {
    track("header_zipcode", {
      action: "zipcode_modal_apply",
      context: this.trackAttributeContext,
      zipcode
    });

    return this;
  }

  fillZipCodeField(zipcode) {
    this.$input.value = zipcode;

    return this;
  }
}
