import { api } from "jiffy-api";
import { on as onEvent } from "common/utils/ui/event";
import { isFunction } from "common/utils/helpers/is";
import DeliverToModalClass from "./deliver-to-modal-class";

export default class DeliverToModalApplicationClass extends DeliverToModalClass {
  constructor(
    props = {
      applyButtonSelector: "",
      modalWrapperSelector: ""
    }
  ) {
    super(props);

    this.props = props;
    this.applyCallback = null;
    this.urlConfirmationParam = "set_address";

    onEvent("click", props.applyButtonSelector, this.onApply.bind(this), true);

    this.setPageScrollPosition();
  }

  fetchDomElements() {
    super.fetchDomElements();

    this.$modalWrapper = document.querySelector(this.props.modalWrapperSelector);
    this.$applyButton = document.querySelector(this.props.applyButtonSelector);

    return this;
  }

  async fetchModalBody() {
    if (this.$modalWrapper.innerHTML.trim() !== "") return;

    const deliverToModalResponse = await api.product.fetchDeliverToModal();
    this.$modalWrapper.innerHTML = deliverToModalResponse.modal;
    this.fetchDomElements();
    super.initModalEvents();
  }

  getZipcode() {
    return super.getModalDataset()?.sessionZipcode;
  }

  getShipAddressId() {
    return super.getModalDataset()?.shipAddressId;
  }

  onApplyCallback(callback) {
    this.applyCallback = callback;

    return this;
  }

  updateApplyButtonLoadingStatus(isLoading = false) {
    this.$applyButton.disabled = isLoading;

    return this;
  }

  onApply() {
    if (isFunction(this.applyCallback)) {
      this.updateApplyButtonLoadingStatus(true);
      this.applyCallback()
        ?.then(this.hideModalWithAttribute.bind(this))
        ?.catch(this.updateApplyButtonLoadingStatus.bind(this, false));
    } else {
      this.hideModalWithAttribute();
    }

    return this;
  }

  hideModalWithAttribute() {
    const url = new URL(window.location);
    url.searchParams.set(this.urlConfirmationParam, "true");
    window.location.href = url;

    return this;
  }

  setPageScrollPosition() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(this.urlConfirmationParam)) window.scroll(0, 0);

    return this;
  }
}
