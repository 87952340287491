import DeliverToModalApplicationClass from "./classes/deliver-to-modal-application-class";
import DeliverToZipCodeFormClass from "./classes/deliver-to-zip-code-form-class";
import DeliverToToggleMobileClass from "./classes/deliver-to-toggle-mobile-class";
import DeliverToUserFormClass from "./classes/deliver-to-user-form-class";
import DeliverToConfirmationWindowApplicationClass from "./classes/deliver-to-confirmation-window-application-class";

class DeliverToApplication {
  constructor() {
    this.initModal();
    this.initConfirmWindow();
    this.initZipCodeForm();
    this.initUserForm();
    this.initDeliverToToggle();

    this.initEvents();
  }

  initModal() {
    this.deliverToModal = new DeliverToModalApplicationClass({
      modalSelector: "#js-deliver-to-modal-application",
      addressSelector: ".js-deliver-to-mob-addr, .js-deliver-to-address",
      modalInputSelector: ".js-deliver-to-modal-input",
      applyButtonSelector: ".js-deliver-to-apply",
      modalWrapperSelector: ".js-deliver-to-modal-wrapper"
    });
  }

  initConfirmWindow() {
    this.confirmWindow = new DeliverToConfirmationWindowApplicationClass({
      chosenModalWindowSelector: ".js-deliver-to-chosen-wrapper",
      mainModalWindowSelector: ".js-deliver-to-submit-form",
      chosenZipcodeSelector: ".js-deliver-to-my-zipcode",
      clearConfirmationSelector: ".js-deliver-to-clear-address"
    });
  }

  initZipCodeForm() {
    this.zipCodeForm = new DeliverToZipCodeFormClass({
      formSelector: ".js-deliver-to-submit-form",
      inputSelector: ".js-deliver-to-modal-input",
      loadingSelector: ".js-deliver-to-modal-loading",
      submitSelector: ".js-deliver-to-modal-submit",
      errorSelector: ".js-deliver-to-modal-form-error"
    });
  }

  initUserForm() {
    this.userForm = new DeliverToUserFormClass({
      formSelector: ".js-deliver-to-user-form",
      inputsSelector: ".js-deliver-to-user-input",
      addressesLinkSelector: ".js-deliver-to-addresses"
    });
  }

  initDeliverToToggle() {
    this.deliverToToggle = new DeliverToToggleMobileClass(".js-deliver-to-toggle-modal", {
      chosenPdpAddressSelector: ".js-deliver-to-pdp-addr",
      chosenAddressSelector: ".js-deliver-to-mob-addr"
    });
  }

  initEvents() {
    this.deliverToToggle.onToggleCallback(context => {
      this.zipCodeForm.updateTrackAttributeContext(context);
      this.deliverToModal.updateTrackAttributeContext(context).openModal();
    });

    this.deliverToModal.onOpenModalCallback(() => {
      document.documentElement.classList.add("locked-mobile");
    });

    this.deliverToModal.onCloseModalCallback(() => {
      this.zipCodeForm.clearForm(this.deliverToModal.getZipcode());
      this.userForm.clearForm(this.deliverToModal.getShipAddressId());
      this.confirmWindow.resetConfirmation();
      document.documentElement.classList.remove("locked-mobile");
    });

    this.zipCodeForm.onSubmitCallback(({ zipcode }) => {
      this.userForm.clearForm(); // clear user form
      this.confirmWindow.fillWindow({ zipcode }).showWindow();
      this.deliverToModal.onApplyCallback(this.userForm.unsetAddress.bind(this.userForm));
    });

    this.userForm.onSelectAddressCallback(({ addressID }) => {
      this.confirmWindow.clearConfirmation(); // clear zipcode confirmation
      this.deliverToModal.onApplyCallback(this.userForm.setAddress.bind(this.userForm, addressID));
    });

    // Click on Change inside confirmation window
    this.confirmWindow.onClearConfirmationCallback(() => {
      this.zipCodeForm.clearForm(this.deliverToModal.getZipcode());
    });

    this.deliverToModal.fetchModalBody().then(() => {
      this.zipCodeForm.fetchDomElements();
      this.confirmWindow.fetchDomElements();
      this.userForm.fetchDomElements();

      // Show confirmation window if zipcode is already set
      this.confirmWindow.setInitialWindow(this.deliverToModal.getZipcode());
    });
  }
}

// eslint-disable-next-line max-lines-per-function
export default () => new DeliverToApplication();
