import { track } from "jiffy-analytics";
import { checkOnePageCookie } from "common/utils/cookie";
import DeliverToZipCodeFormClass from "../classes/deliver-to-zip-code-form-class";
import "./deliver-to-tooltip.scss";

const $getTooltip = () => document.getElementById("js-deliver-to-tooltip");
const $getEntryModal = () => document.getElementById("js-guest-entry-modal");

const $getCloseButtonWrapper = () => document.querySelector(".js-zip-bar-exp-close-button-wrap");

const toggleTooltipVisibility = visible => {
  const $tooltip = $getTooltip();
  const invisibleClass = "deliver-to-tooltip--invisible";
  if (!$tooltip) return;

  if (visible) {
    $tooltip.classList.remove("hidden");
    setTimeout(() => {
      $tooltip.classList.remove(invisibleClass); // Do no prevent animation
    }, 0);
  } else {
    $tooltip.classList.add(invisibleClass);
    setTimeout(() => {
      $tooltip.classList.add("hidden"); // Wait end of animation
    }, 300);
  }
};

// If a user clicks some link on the guest popup we still should show the zipcode tooltip after page reload
const getCookieName = () => {
  const isLoginPage = window.location.search.includes("source=guest_entry_modal");
  return isLoginPage ? "deliver-to-zipcode-popup-login" : "deliver-to-zipcode-popup";
};

const handleFormSubmit = () => {
  const desktopForm = new DeliverToZipCodeFormClass({
    formSelector: "#js-deliver-to-tooltip-form",
    inputSelector: ".js-deliver-to-tooltip-input",
    loadingSelector: ".js-deliver-to-tooltip-loading",
    submitSelector: "#js-deliver-to-tooltip-submit",
    errorSelector: "#js-deliver-to-tooltip-form-error"
  });

  desktopForm.onSubmitCallback(({ zipcode }) => {
    desktopForm.setLoading(true);
    desktopForm.preventLoading();
    track("header_zipcode", {
      action: "zipcode_tooltip_apply",
      context: "header",
      zipcode
    });
    window.location.reload();
  });
};

const initDeliverToTooltip = () => {
  const $tooltip = $getTooltip();

  const shouldBeDisabled = checkOnePageCookie(getCookieName());

  if (!$tooltip || shouldBeDisabled) {
    return;
  }

  toggleTooltipVisibility(true);

  handleFormSubmit();

  const closeButtonWrapper = $getCloseButtonWrapper();

  document.addEventListener("click", event => {
    const clickInside =
      event.composedPath().includes($tooltip) || event.composedPath().includes($getEntryModal());

    const clickedOnXButton = event.composedPath().includes(closeButtonWrapper);

    const isOriginalTooltipAndClickedOutside = !clickInside;

    if (clickedOnXButton || isOriginalTooltipAndClickedOutside) {
      toggleTooltipVisibility(false);
    }
  });
};

export default initDeliverToTooltip;
