import { readCookie } from "common/utils/cookie";
import initDeliverToTooltip from "./deliver-to-tooltip/deliver-to-tooltip";
import initDeliverToTooltipModal from "./deliver-to-tooltip-mobile/deliver-to-tooltip-mobile";

const initDeliverToTooltips = () => {
  // If selectedCity is "true", it means that current user/guest has selected zip code
  const selectedCity = readCookie("is_city_selected");

  if (selectedCity !== "true") {
    initDeliverToTooltip();
    initDeliverToTooltipModal();
  }
};

export default initDeliverToTooltips;
