/* eslint-disable import/prefer-default-export, max-lines-per-function, max-params */

export const on = (
  eventName,
  elementSelector,
  handler,
  capture = false,
  documentElement = document
) => {
  documentElement.addEventListener(
    eventName,
    function (e) {
      for (let { target } = e; target && target !== this; target = target.parentNode) {
        if (target.matches(elementSelector)) {
          handler.call(target, e, target);
          break;
        }
      }
    },
    capture
  );
};

/* eslint-enable import/prefer-default-export */
