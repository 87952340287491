import DeliverToConfirmationWindowClass from "./deliver-to-confirmation-window-class";

export default class DeliverToConfirmationWindowApplicationClass extends DeliverToConfirmationWindowClass {
  constructor(props) {
    super(props);

    this.initialWindow = false;
    this.initialZipcode = null;
  }

  setInitialWindow(zipcode) {
    if (!zipcode) return null;
    this.initialWindow = true;
    this.initialZipcode = zipcode;
    this.fillAndShowWindow();

    return this;
  }

  resetConfirmation() {
    if (this.initialWindow) {
      this.fillAndShowWindow();
    } else {
      super.clearConfirmation();
    }
  }

  fillAndShowWindow() {
    super.fillWindow({ zipcode: this.initialZipcode });
    super.showWindow();

    return this;
  }
}
