import DeliverToToggleClass from "./deliver-to-toggle-class";

export default class DeliverToToggleDesktopClass extends DeliverToToggleClass {
  constructor(
    selector,
    options = {
      addressFieldSelector: "",
      chosenAddressSelector: "",
      titleSelector: "",
      usernameSelector: ""
    }
  ) {
    super(selector, options);

    this.$chosenAddress = document.querySelector(options.chosenAddressSelector);
    this.$title = document.querySelector(options.titleSelector);
    this.$username = document.querySelector(options.usernameSelector);

    this.fillAddress(super.getAddressFromCookies());
  }

  fillAddress({ address = "", username = "", zipcode = "" }) {
    this.fillChosenAddress(address, zipcode);
    this.fillTitle(address);
    this.fillUserName(username);
    super.fillPDPAddress(address, username);

    return this;
  }

  fillChosenAddress(address = "", zipcode = "") {
    if (this.$chosenAddress === null) return this;

    const containsUSZipCode = address ? /\d{5}(-\d{4})?$/.test(zipcode || address) : false;
    const isCanada = document.documentElement.lang === "en-CA";
    const zipcodeNotMatchedWithPageCountry =
      (containsUSZipCode && isCanada) || (!containsUSZipCode && !isCanada);

    if (address && zipcodeNotMatchedWithPageCountry) {
      this.$chosenAddress.innerHTML = "Select Your Address";
    } else if (address) {
      this.$chosenAddress.innerHTML = address.replace(/.+, /, "");
    } else {
      this.$chosenAddress.innerHTML = "Select Your Address";
    }

    return this;
  }

  fillTitle(address = "") {
    if (this.$title === null) return this;

    this.$title.innerText = address ? "Deliver to" : "Hello";

    return this;
  }

  fillUserName(username = "") {
    if (this.$username === null) return this;

    this.$username.innerHTML = username;

    return this;
  }
}
